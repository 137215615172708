import { useEffect, useState } from "react";
import classes from "./MaintenanceComponent.module.scss"
import { MaintenanceTable } from "../table/MaintenanceTable";
import { MaintenanceAppBar } from "./appbar/MaintenanceAppBar";
import { Box } from "@mui/material";
import useInitializeWindow from "../../../hooks/useInitializeWindow";
import usePersistToken from "../../../hooks/usePersistToken";
import useGetSourceDocuments from "../../../hooks/useGetSourceDocuments";
import { DocumentDialog } from "../../mainPage/document/DocumentDialog";
import { SourceDocument } from "../../../model/SourceDocument";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { NewSourceDocumentDialog } from "../dialog/NewSourceDocumentDialog";
import * as sourceDocumentApiHelper from "../../../repository/sourceDocument/sourceDocumentApiHelper"
import { EditScreenDialog } from "./edit/dialogs/EditScreenDialog";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { updateSourceDocument } from "../../../store/slices/sourceDocumentSlice";
import { Section } from "../../../model/Section";
import { findTotalScoreSection, updateDocument } from "../../../store/slices/documentSlice";
import { documentSelector } from "../../../store/selectors/documentSelectors";
import { Document } from "../../../model/Document";
import AppContext from "../../global";


export const MaintenanceComponent: React.FC = () => {
    const dispatch = useAppDispatch();
    useInitializeWindow();
    usePersistToken();

    const confirmationDialog = useConfirmationDialog();

    const [isDocumentDialogOpen, setIsDocumentDialogOpen] = useState(false);
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const [isMaintenanceEditOpen, setIsMaintenanceEditOpen] = useState(false);

    const [selectedDocument, setSelectedDocument] = useState<SourceDocument>();
    const [selectedPreviewDocument, setSelectedPreviewDocument] = useState<Document>();
    const [filterText, setFilterText] = useState('');

    const [sourceDocuments, reloadData, initMessage] = useGetSourceDocuments(filterText);
    const [totalParentId, setTotalParentId] = useState<number>(0);

    let mySelectorDocument = useAppSelector(documentSelector);

    function showDocument(id: number) {
        let preview:SourceDocument = sourceDocuments.find(c => c.id == id);
        let doc:Document = preview.documentTemplate;
        if(!!preview){
            dispatch(updateDocument(doc));
            AppContext.currentAnswerCategories = doc.answerCategories;
            updateTotalParentId(preview);
            setSelectedPreviewDocument(doc);
            setIsDocumentDialogOpen(true);
        }
    }

    function deleteDocument(id: number) {
        let document = sourceDocuments.find(c => c.id === id);

        confirmationDialog.show({
            message: `Are you sure you want to delete ${document.documentTemplate.screenReason.trim()}? This action cannot be undone!`,
            title: 'Please Confirm',
            onCancel: () => {
                confirmationDialog.hide();
                reloadData({});
            },
            onConfirm: async () => {
                await sourceDocumentApiHelper.DeleteDocument(document.id);
                confirmationDialog.hide();
                reloadData({})
            },
            cancelText: "No",
            confirmText: "Yes",
        });
    }

    function copyDocument(id: number) {
        let document = sourceDocuments.find(c => c.id === id);
        setSelectedDocument(document);

        setIsCopy(true);
        setIsNewDialogOpen(true);
    }

    async function handleCopyDocument(copiedDocument: SourceDocument) {

        if (!copiedDocument.documentTemplate.screenReason) {
            confirmationDialog.show({
                message: `New screen name cannot be empty!`,
                title: 'Screen Name Error',
                onConfirm: () => {
                    confirmationDialog.hide();
                }
            });

            return;
        }

        reloadData({});

        const newDocumentName = copiedDocument.documentTemplate.screenReason.replace(/\s+/g, ' ').trim();

        if (!!sourceDocuments &&
            sourceDocuments
                .map(item => item.documentTemplate?.screenReason?.replace(/\s+/g, ' ').toLowerCase().trim())
                .filter(item => item === newDocumentName.toLowerCase())
                .length > 0

        ) {
            confirmationDialog.show({
                message: "Screen names cannot be duplicated. Please choose a different name.",
                title: `${newDocumentName} Already Exists`,
                onConfirm: () => {
                    confirmationDialog.hide();
                    reloadData({});
                }
            });

            return;
        }

        copiedDocument.orgId = initMessage.orgId;
        await sourceDocumentApiHelper.CopyDocument(copiedDocument);
        reloadData({});
        setIsNewDialogOpen(false);
    }

    function editDocument(id: number) {
        let document = sourceDocuments.find(c => c.id === id);
        setSelectedDocument(document);
        dispatch(updateSourceDocument(document));

        setIsMaintenanceEditOpen(true);
    }

    function updateTotalParentId(document: SourceDocument) {
        let totalSection = findTotalScoreSection(document.documentTemplate);
        if (totalSection) {
            setTotalParentId(totalSection.parentId);
        } else {
            var rootsection: Section;
            // use first section of document root instead
            for (let section of document.documentTemplate.sections) {
                if (section.kind === "section") {
                    rootsection = section as Section;
                    break;
                }
            }
            setTotalParentId(rootsection.id);
        }
    }

    function handleSaveFailed(message: string) {
        confirmationDialog.show({
            message: `${message}. Source document failed to save. Documents will refresh.`,
            title: 'Source Document Save Failed',
            onConfirm: () => {
                confirmationDialog.hide();
                setIsMaintenanceEditOpen(false);
                reloadData({});
            }
        });
    }

    function addNewScreen() {
        setIsCopy(false);
        setIsNewDialogOpen(true);
    }

    return (
        <>
            <MaintenanceAppBar
                filterSourceDocuments={setFilterText}
                addNewScreen={addNewScreen} />

            <Box className={classes.box}>
                <MaintenanceTable
                    screens={sourceDocuments}
                    showDocument={showDocument}
                    deleteDocument={deleteDocument}
                    copyDocument={copyDocument}
                    editDocument={editDocument} />
            </Box>

            <EditScreenDialog
                isOpen={isMaintenanceEditOpen}
                sourceDocument={selectedDocument}
                saveFailed={handleSaveFailed}
                close={() => {
                    setIsMaintenanceEditOpen(false);
                    reloadData({});
                }} />

            <DocumentDialog
                document={selectedPreviewDocument}
                isOpen={isDocumentDialogOpen}
                handleSaveEditDocument={async () => { }}
                handleCancelEditDocument={() => {
                    setIsDocumentDialogOpen(false);
                    reloadData({});
                }}
                handlePrintDocument={() => { }}
                patientName={"Preview"}
                previewMode={true} />

            <NewSourceDocumentDialog
                isOpen={isNewDialogOpen}
                isCopy={isCopy}
                sourceDocument={selectedDocument}
                handleSaveDocument={handleCopyDocument}
                handleCloseDialog={() => {
                    setIsNewDialogOpen(false);
                    reloadData({});
                }}
            />

            {confirmationDialog.component}
        </>
    );
};