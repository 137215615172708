import { IsQuestion, IsSection, IsTotal, Document, IsComment } from "../../../../../model/Document";
import { SourceDocument } from "../../../../../model/SourceDocument";
import { LargeTextQuestion } from "../../../../../model/LargeTextQuestion";
import { SingleselectQuestion } from "../../../../../model/SingleselectQuestion";
import { SelectQuestionOption } from "../../../../../model/SelectQuestionOption";
import { Total } from "../../../../../model/Total";
import { Section } from "../../../../../model/Section";
import { Question } from "../../../../../model/Question";
import React, { useState } from "react";
import { Dropdown } from "../../../../common/Dropdown/Dropdown";
import { DropdownOption } from "../../../../common/Dropdown/DropdownOption/DropdownOption";
import { CustomDatePicker } from "../../../../common/DatePicker/DatePicker";
import { CheckBox } from "../../../../common/CheckBox/CheckBox";
import classes from "./styles/rowstyles.module.scss";
import { TextInput } from "../../../../common/TextInput/TextInput";
import { useAppDispatch } from "../../../../../store/hooks";
import { updateTitle, updateCaption, updateParent, updateQuestion, updateRequired, updateShowScore, updateShowNotes, updateNoteRequired, updateActive, updateOptions, updatePrompt, updateEffectiveFrom, updateEffectiveThru, updateNumericRange } from "../../../../../store/slices/sourceDocumentSlice";
import dayjs, { Dayjs } from "dayjs";
import { NumericQuestionOption } from "../../../../../model/NumericQuestionOption";
import { Kinds } from "../../../../../model/Kind";
import { maxDate, minDate } from "../../../../../model/DateQuestion";


export type QuestionProps = {
    part: Question | Total | Section;
    parent: Section | Total;
    sourceDocument: SourceDocument;
    parentsList: (Section | Total)[];
};

export const EditRowFieldsComponent = (props: QuestionProps) => {
    const dispatch = useAppDispatch();
    const [effectiveFromValue, setEffectiveFromValue] = useState<Dayjs | null>(props.part?.effectiveFrom ? dayjs(props.part.effectiveFrom) : null);
    const [effectiveThruValue, setEffectiveThruValue] = useState<Dayjs | null>(props.part?.effectiveThru ? dayjs(props.part.effectiveThru) : null);
    const [formAnswerCategoriesValues] = useState(props.sourceDocument?.documentTemplate?.answerCategories?.filter(c => c.answerCategoryType?.toLowerCase() == "value"));
    const [formAnswerCategoriesRanges] = useState(props.sourceDocument?.documentTemplate?.answerCategories?.filter(c => c.answerCategoryType?.toLowerCase() == "range"));
    const [maxFromDate, setMaxFromDate] = useState<Dayjs | null>(undefined);
    const [minThruDate, setMinThruDate] = useState<Dayjs | null>(undefined);
    const [maxFromErrorMessage, setMaxFromErrorMessage] = useState<string>('Date must be before 01/01/2100.');
    const [minThruErrorMessage, setMinThruErrorMessage] = useState<string>('Date must be after 12/31/1899.');


    function onParentChanged(newParentID: number) {
        dispatch(
            updateParent({
                id: props.part.id,
                originalParentID: props.parent.id,
                newParentID: newParentID
            })
        );
    }

    function onEffectiveFromChanged(newEffectiveFrom: Dayjs) {
        setMaxFromDate(undefined)
        setMaxFromErrorMessage('Date must be before 01/01/2100.');
        if (newEffectiveFrom && effectiveThruValue) {
            setMaxFromDate(effectiveThruValue)
            setMaxFromErrorMessage('Effective From date must be less than the Effective Thru date.');
        }
        setEffectiveFromValue(newEffectiveFrom);

        if(!newEffectiveFrom 
            || !newEffectiveFrom.isValid()
            || newEffectiveFrom < dayjs(minDate)
            || newEffectiveFrom > dayjs(maxDate)
        ) return;

        if((newEffectiveFrom <= effectiveThruValue) ){
            dispatch(
                updateEffectiveFrom({
                    id: props.part.id,
                    effectiveFrom: newEffectiveFrom?.isValid() ? newEffectiveFrom?.toISOString() : null
                })
            );
            onEffectiveThruChanged(effectiveThruValue) // clears any errors
        }
    }

    function onEffectiveThruChanged(newEffectiveThru: Dayjs) {
        setMinThruDate(undefined)
        setMinThruErrorMessage('Date must be after 12/31/1899.');
        if (newEffectiveThru && effectiveFromValue) {
            setMinThruDate(effectiveFromValue)
            setMinThruErrorMessage('Effective Thru date must be greater than the Effective From date.');
        }
        setEffectiveThruValue(newEffectiveThru);

        if(!newEffectiveThru 
            || !newEffectiveThru.isValid()
            || newEffectiveThru < dayjs(minDate)
            || newEffectiveThru > dayjs(maxDate)
        ) return;

        if((effectiveFromValue <= newEffectiveThru)){
            dispatch(
                updateEffectiveThru({
                    id: props.part.id,
                    effectiveThru: newEffectiveThru?.isValid() ? newEffectiveThru?.toISOString() : null
                })
            );
            onEffectiveFromChanged(effectiveFromValue); // clears any errors
        }
    }

    function onTitleChanged(title: string) {
        dispatch(
            updateTitle({
                id: props.part.id,
                title: title
            })
        );
    }

    function onCaptionChanged(caption: string) {
        dispatch(
            updateCaption({
                id: props.part.id,
                caption: caption
            })
        );
    }

    function onQuestionChanged(question: string) {
        dispatch(
            updateQuestion({
                id: props.part.id,
                question: question
            })
        );
    }

    function onPromptChanged(prompt: string) {
        dispatch(
            updatePrompt({
                id: props.part.id,
                prompt: prompt
            })
        );
    }

    function onShowScoreChanged(showScore: boolean) {
        dispatch(
            updateShowScore({
                id: props.part.id,
                showScore: showScore
            })
        );
    }

    function onShowNotesChanged(showNotes: boolean) {
        dispatch(
            updateShowNotes({
                id: props.part.id,
                showNotes: showNotes
            })
        );
    }

    function onRequiredChanged(required: boolean) {
        dispatch(
            updateRequired({
                id: props.part.id,
                required: required
            })
        );
    }

    function onNoteRequiredChanged(noteRequired: boolean) {
        dispatch(
            updateNoteRequired({
                id: props.part.id,
                noteRequired: noteRequired
            })
        );
    }

    function onActiveChanged(active: boolean) {
        dispatch(
            updateActive({
                id: props.part.id,
                active: active
            })
        );
    }

    function isControlDisabled(item: Question | Total | Section, ctrlName: string): boolean {
        let disabled = props.sourceDocument.isInUse;
        const itemInUse = props.sourceDocument.isInUse;

        if ((ctrlName === "showScore") && (IsSection(item) ||
            IsTotal(item) ||
            item.kind === "COMMENT")) {
            disabled = true;
            return disabled;
        }

        if ((ctrlName === "showNotes" ||
            ctrlName === "required") && (IsSection(item) ||
                IsTotal(item) ||
                item.kind === "COMMENT")) {
            disabled = true;
            return disabled;
        }

        if (ctrlName === "effectiveFrom") {
            disabled = itemInUse || (IsSection(item) && item.formatType === "HEADER");
            return disabled;
        }

        if (ctrlName === "effectiveThru") {
            if (IsSection(item) && item.formatType === "HEADER") {
                return true;
            }

            return false;
        }

        if (ctrlName === "question" ||
            ctrlName === "title" ||
            ctrlName === "caption") {
            return false;
        }

        if (ctrlName === "answerCategory") {
            disabled = itemInUse || (!(item.kind === "select" || item.kind === "numeric"));
            return disabled;
        }

        if (ctrlName === "prompt") {
            disabled = !(item.kind === "TEXT" || item.kind === "LARGE_TEXT");
            return disabled;
        }

        if (ctrlName === "active" && IsSection(item) && item.formatType === "HEADER") {
            disabled = true; return disabled;
        }

        return disabled;
    }

    function isChild(aParent: Section | Total, aChild: Question | Section | Total) {
        aParent.parts?.forEach(child => {
            if (child.id === aChild.id)
                return true;
            else if ((IsSection(child) || IsTotal(child)) && isChild(child, aChild)) {
                return true;
            }
        });

        return false;
    }

    function onAnswerCategoryChange(ac: string) {
        let answerCategory = props.sourceDocument?.documentTemplate?.answerCategories?.
            find(c => c.answerCategoryName?.toLowerCase() === ac.toLowerCase());

        if (answerCategory.answerCategoryType.toLowerCase() === "value")
            dispatch(
                updateOptions({
                    id: props.part.id,
                    options: props.sourceDocument?.documentTemplate?.answerCategories?.
                        find(c => c.answerCategoryName?.toLowerCase() === ac.toLowerCase()).options,
                    answerCategory: ac
                })
            );

        if (answerCategory.answerCategoryType.toLowerCase() === "range") { }
        dispatch(
            updateNumericRange({
                id: props.part.id,
                min: (answerCategory.options[0] as NumericQuestionOption).valueFrom,
                max: (answerCategory.options[0] as NumericQuestionOption).valueTo,
                answerCategory: answerCategory.answerCategoryName,
                options: answerCategory.options
            })
        );
    }

    const kindDescription = Kinds.find((kind) => kind.kindKey === props.part.kind) !== undefined
    ? Kinds.find((kind) => kind.kindKey === props.part.kind).Desc
    :''; 

    return (
        <>
            <div className={classes.row}>
                <div className={classes.col_15}>
                    Kind
                    <input type="text" disabled value={kindDescription} />
                </div>
                {((IsQuestion(props.part) && props.part.kind === "check") || IsSection(props.part)) &&
                    (<div className={classes.col_15}>
                        Format Type
                        <input type="text" disabled value={props.part.formatType} />
                    </div>)}
                {(IsQuestion(props.part) || IsComment(props.part) || IsTotal(props.part)) &&
                    (<div className={classes.col_85}>
                        Question Text
                        <TextInput disabled={isControlDisabled(props.part, 'question')}
                            className={classes.inputField}
                            aria-required
                            onChange={(e) => onQuestionChanged(e.target.value)}
                            onBlur={(e) => onQuestionChanged(e.target.value)}
                            defaultValue={props.part.question}
                        />
                    </div>)}
                {IsSection(props.part) &&
                    (<>
                        <div className={classes.col_45}>
                            Title Text
                            <TextInput disabled={isControlDisabled(props.part, 'title')}
                                className={classes.inputField}
                                aria-required
                                onChange={(e) => onTitleChanged(e.target.value)}
                                onBlur={(e) => onTitleChanged(e.target.value)}
                                defaultValue={props.part.title}
                            />
                        </div>
                        <div className={classes.col_40}>
                            Caption Text
                            <TextInput disabled={isControlDisabled(props.part, 'caption')}
                                className={classes.inputField}
                                aria-required
                                onChange={(e) => onCaptionChanged(e.target.value)}
                                onBlur={(e) => onCaptionChanged(e.target.value)}
                                defaultValue={props.part.caption}
                            />
                        </div>
                    </>
                    )}
            </div>
            <div className={classes.row}>
                {!isControlDisabled(props.part, 'parentId') &&
                    (<div className={classes.col_20}>
                        Parent
                        <Dropdown
                            className={classes.select}
                            onChange={(event) => onParentChanged(event.target.value as number)}
                            value={props.parent.id}
                        >
                            {props.parentsList.map((option) => {
                                return (option.id !== -1 || IsSection(props.part) || IsTotal(props.part)) // only show root for section or total
                                    && option.id !== props.part.id // can't set self as parent
                                    && !((IsSection(props.part) || IsTotal(props.part)) && isChild(props.part, option)) // section and total can't set descendent as parent
                                    && (!IsTotal(option) || props.part.kind === "COMMENT") // only allowing comments under totals
                                    && (
                                        <DropdownOption key={option.id} value={option.id}>
                                            {(option as Section)?.title || (option as Total)?.question}
                                        </DropdownOption>
                                    );
                            })}
                        </Dropdown>
                    </div>)
                }
                {isControlDisabled(props.part, 'parentId') && (<div className={classes.col_20} ></div>)}

                {!isControlDisabled(props.part, 'effectiveFrom') &&
                    (<div className={classes.col_20}>
                        Effective From
                        <CustomDatePicker
                            dateValue={effectiveFromValue}
                            onValueChanged={(newValue) => onEffectiveFromChanged(newValue)}
                            maxDate={maxFromDate}
                            maxDateErrorMsg={ maxFromErrorMessage}
                            minDateErrorMsg="Date must be after 12/31/1899."
                        />
                    </div>)
                }
                {isControlDisabled(props.part, 'effectiveFrom') && (<div className={classes.col_20}></div>)}

                {!isControlDisabled(props.part, 'effectiveThru') &&
                    (<div className={classes.col_20}>
                        Effective Thru
                        <CustomDatePicker
                            dateValue={effectiveThruValue}
                            onValueChanged={(newValue) => onEffectiveThruChanged(newValue)}
                            minDate={minThruDate}
                            minDateErrorMsg={minThruErrorMessage}
                            maxDateErrorMsg="Date must be before 01/01/2100."
                        />
                    </div>)
                }
                {isControlDisabled(props.part, 'effectiveThru') && (<div className={classes.col_20} ></div>)}

                <div className={classes.col_40}>
                    <div className={classes.editor_CheckArea}>
                        {!isControlDisabled(props.part, 'showScore') && (IsQuestion(props.part) || IsTotal(props.part)) && (<div className={classes.col} >
                            <span>Show Score</span>
                            <CheckBox className={classes.editor_CheckBoxes}
                                checked={props.part.showScore || false}//TODO: real value saving
                                onChange={(e) => onShowScoreChanged(e.target.checked)}
                            />
                        </div>)}
                        {!isControlDisabled(props.part, 'showNotes') && (IsQuestion(props.part) || IsTotal(props.part)) && (<div className={classes.col} >
                            <span>Notes</span>
                            <CheckBox className={classes.editor_CheckBoxes}
                                checked={props.part.showNotes || false}
                                onChange={(e) => onShowNotesChanged(e.target.checked)}
                            />
                        </div>)}
                        {!isControlDisabled(props.part, 'required') && (IsQuestion(props.part) || IsTotal(props.part)) && (<div className={classes.col} >
                            <span>Required</span>
                            <CheckBox className={classes.editor_CheckBoxes}
                                checked={props.part.required || false}
                                onChange={(e) => onRequiredChanged(e.target.checked)}
                            />
                        </div>)}
                        {!isControlDisabled(props.part, 'noteRequired') && IsQuestion(props.part) && (<div className={classes.col} >
                            <span>Note Required</span>
                            <CheckBox className={classes.editor_CheckBoxes}
                                checked={props.part.noteRequired || false}
                                onChange={(e) => onNoteRequiredChanged(e.target.checked)}
                            />
                        </div>)}
                        {!isControlDisabled(props.part, 'active') && (<div className={classes.col} >
                            <span>Active</span>
                            <CheckBox className={classes.editor_CheckBoxes}
                                checked={props.part.active || false}
                                onChange={(e) => onActiveChanged(e.target.checked)}
                            />
                        </div>)}
                    </div>
                </div>
            </div>

            <div className={classes.row}>
                {!isControlDisabled(props.part, 'answerCategory') && (<div className={classes.col_20}>
                    Answer Category
                    <Dropdown
                        className={classes.select}
                        onChange={(e) => { onAnswerCategoryChange(e.target.value) }}
                        value={!!(props.part as any).answerCategory ? (props.part as any).answerCategory : "note here"}
                    >
                        {props.part.kind === "select" && formAnswerCategoriesValues?.map((option) => {
                            return (
                                <DropdownOption key={option.answerCategoryId} value={option.answerCategoryName}>
                                    {option.answerCategoryName}
                                </DropdownOption>
                            );
                        })}
                        {props.part.kind === "numeric" && formAnswerCategoriesRanges?.map((option) => {
                            return (
                                <DropdownOption key={option.answerCategoryId} value={option.answerCategoryName}>
                                    {option.answerCategoryName}
                                </DropdownOption>
                            );
                        })}
                    </Dropdown>
                </div>
                )}
                {!isControlDisabled(props.part, 'prompt') && (<div className={classes.col_80}>
                    Prompt
                    <TextInput disabled={isControlDisabled(props.part, 'caption')}
                        className={classes.inputField}
                        aria-required
                        onChange={(e) => onPromptChanged(e.target.value)}
                        onBlur={(e) => onPromptChanged(e.target.value)}
                        defaultValue={(props.part as LargeTextQuestion).prompt}
                    />
                </div>)}
            </div>
        </>
    )
};
