import React, { useState } from "react";
import {
    Checkbox,
    FormGroup,
    FormControlLabel,
    TextField,
    Box,
} from "@mui/material";
import {
    updateAnswer,
} from "../../../store/slices/documentSlice";
import * as cssStyles from "../../../styles/componentStyles";
import { useAppDispatch } from "../../../store/hooks";
import { MultiselectQuestion } from "../../../model/MultiselectQuestion";
import { Label } from "../../common/Label/Label"
import { QuestionComment } from "../questionComment/questionCommentComponent";

export const MultiOptComponent: React.FC<{
    question: MultiselectQuestion;
}> = (props) => {
    const dispatch = useAppDispatch();
    const [valueFields, setValueFields] = useState<string[]>(
        props.question?.answer
    );

    const isChecked = (option: string): boolean =>
        !valueFields ? false : valueFields.indexOf(option) >= 0;

    const toggle = (option: string, newValue: "on" | "off"): void => {
        let newArray: string[] = [];

        if (newValue === "on" && !isChecked(option)) {
            newArray = [...valueFields, option];
        } else if (newValue === "off" && isChecked(option)) {
            const index = valueFields.indexOf(option);

            newArray = [
                ...valueFields.slice(0, index),
                ...valueFields.slice(index + 1),
            ];
        }

        setValueFields(newArray);
    };

    function UpdateCheckedState(
        e: React.ChangeEvent<HTMLInputElement>,
        option: string
    ) {
        if (e.target.checked) {
            toggle(option, "on");
        } else {
            toggle(option, "off");
        }
    }

    function handleCheckBoxOnBlur() {
        dispatch(
            updateAnswer({
                id: props.question?.id,
                answer: valueFields as any,
            })
        );
    }

    return (
        <div style={cssStyles.parentDivStyle}>
            <Box sx={cssStyles.boxStyle}>
                <Label>{props.question?.question}</Label>
                <FormGroup sx={cssStyles.formGroupStyle}>
                    {props.question.options?.map((option) => (
                        <FormControlLabel
                            labelPlacement="end"
                            sx={cssStyles.multiStyle}
                            control={
                                <Checkbox
                                    style={cssStyles.multiStyle}
                                    checked={isChecked(option)}
                                    onChange={(e) => UpdateCheckedState(e, option)}
                                    onBlur={(e) => handleCheckBoxOnBlur()}
                                />
                            }
                            key={option}
                            label={option}
                        />
                    ))}
                </FormGroup>
                <div>
                    {props.question.showNotes && (
                        <QuestionComment
                            noteText={props.question?.note}
                            showNotes={props.question?.showNotes}
                            noteRequired={props.question?.noteRequired}
                            questionId={props.question?.id}
                        />
                    )}
                </div>
            </Box>
        </div>
    );
};
