
import { Document } from "../../../model/Document";
import React from "react";
import { findTotalScoreSection } from "../../../store/slices/documentSlice";
import classes from './DocumentScreenTableScreenResultItem.module.scss';
import { Label } from "../../common/Label/Label";
import { PDPMTotalScoreComponent } from "../../totals/documentPDPMTotal/PDPMTotalScoreComponent";

type Props = {
    document: Document
};

export const DocumentScreenTableScreenResultItem: React.FC<Props> = (props: Props) => {
    let totalSection = findTotalScoreSection(props.document);

    if (!!totalSection && totalSection.kind === "PDPMScore")
        return <PDPMTotalScoreComponent totalSection={totalSection} pdpmscores={props.document.screenResultJSON} gridMode={true} />;
    else
        return <div>{props.document?.screenResult == "" ? 'Incomplete' : props.document?.screenResult}</div>;
}