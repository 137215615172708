import React, { useState } from "react";
import { Box } from "@mui/material";
import { updateAnswer } from "../../../store/slices/documentSlice";
import { useAppDispatch } from "../../../store/hooks";
import * as helperMethods from "../../helpers/helperMethods";
import { CheckQuestion } from "../../../model/CheckQuestion";
import classes from "./checkQuestionComponent.module.scss";
import { QuestionComment } from "../questionComment/questionCommentComponent";
import { Label } from "../../common/Label/Label"
import { CheckBox } from "../../common/CheckBox/CheckBox";

import AppContext from "../../global";

type Props = { question: CheckQuestion };

export const CheckQuestionComponent: React.FC<Props> = (props: Props) => {
    const dispatch = useAppDispatch();
    const [checkFieldValue, setCheckFieldValue] = useState(props.question.answer ?? false);

    const isRequired = helperMethods.isRequired(props);

    function onChangeChecked(checked: boolean) {
        setCheckFieldValue(checked)

        dispatch(
            updateAnswer({
                id: props.question.id,
                answer: checked ? checked : "",
            })
        );
    }

    var label = (<Label styleName={classes.label}>
        {props.question.required
            ? props.question.question + " *"
            : props.question.question}
    </Label>)
    var checkbox = (<CheckBox
        required={isRequired}
        checked={!!checkFieldValue}
        onChange={(e) => onChangeChecked(e.target.checked)}
    />)

    return (
        <Box className={classes.container}>
            <div className={classes.checkBoxDiv}>
                {props.question.formatType == "CHECK_LEFT" ? checkbox : label}
                {props.question.formatType == "CHECK_RIGHT" ? checkbox : label}
            </div>

            <div>
                {props.question.showNotes && (
                    <QuestionComment
                        noteText={props.question.note}
                        showNotes={props.question.showNotes}
                        noteRequired={props.question.noteRequired}
                        questionId={props.question.id}
                    />
                )}
            </div>
        </Box>
    );
};

export default CheckQuestionComponent;
