import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { Label } from "../../common/Label/Label";
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "../../common/Button/Button";
import classes from "./NewSourceDocumentDialog.module.scss"
import { Document } from "../../../model/Document";
import { SourceDocument } from "../../../model/SourceDocument";
import { TextInput } from "../../common/TextInput/TextInput";

type Props = {
    isOpen: boolean;
    isCopy: boolean;
    handleCloseDialog: () => void;
    handleSaveDocument: (document: SourceDocument) => void;
    sourceDocument: SourceDocument;
}

export const NewSourceDocumentDialog: React.FC<Props> = (props) => {

    const [newScreenName, setNewScreenName] = useState('');

    function copyDocument() {
        let newDocument = JSON.parse(JSON.stringify(props.sourceDocument));
        newDocument.documentTemplate.screenReason = newScreenName;

        props.handleSaveDocument(newDocument);
    }

    function saveNewDocument() {
        let newDocument: SourceDocument = {
            id: 0,
            orgId: '',
            isInUse: false,
            isGlobal: false,
            documentTemplate: {
                "id": "",
                "orgId": "",
                "patient": null,
                "facility": null,
                "sections": [{
                    "id": 10,
                    "kind": "section",
                    "parts": [],
                    "title": newScreenName,
                    "caption": "",
                    "disciplines": "",
                    "formatType": "HEADER"
                }],
                "patientId": null,
                "facilityId": null,
                "screenDate": "",
                "disciplines": null,
                "accessRights": {
                    "isGlobal": false,
                    "includedOrgs": []
                },
                "facilityName": null,
                "screenReason": newScreenName,
                "screenResult": "",
                "requestedDate": "",
                "completingUser": null,
                "screenResultJSON": null,
                "sourceDocumentId": null,
                "startOfCareDates": null,
                "resultDefinitions": [],
                "allowedDisciplines": [
                    "PT",
                    "OT",
                    "ST"
                ]
            } as Document
        };

        props.handleSaveDocument(newDocument);
    }

    function saveDocument() {
        if (props.isCopy) {
            copyDocument();
        }
        else {
            saveNewDocument();
        }
    }

    function cancel() {
        props.handleCloseDialog();
    }

    return (
        <Dialog
            scroll="paper"
            fullWidth
            maxWidth="xs"
            open={props.isOpen}
            disableScrollLock>
            <DialogTitle>
                <div className={classes.title_div}>
                    <Label styleName={classes.title}>
                        {props.isCopy ?
                            'Copy ' + props.sourceDocument?.documentTemplate?.screenReason + ' screen'
                            : 'Add new screen'}
                    </Label>
                    <IconButton aria-label="close" onClick={cancel}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent dividers>
                <Stack className={classes.message_box}>
                    <Label styleName={classes.message}>New Screen Name:</Label>
                    <TextInput
                        onBlur={(e) => { setNewScreenName(e.target.value) }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button type="secondary" onClick={cancel}>
                    Cancel
                </Button>
                <Button type="primary" onClick={saveDocument} >
                    {props.isCopy ? 'Copy' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}