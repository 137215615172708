import { BaseQuestion } from "./BaseQuestion";


export type DateQuestion = BaseQuestion & {
    kind: "DATE";
    answer: Date;
    min?: Date;
    max?: Date;
};

// Define the minDate constant
export const minDate: Date = new Date('1900-01-01');

// Define the maxDate constant
export const maxDate: Date = new Date('2099-12-31');

