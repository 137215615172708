import React, { useState } from "react";
import { Box, Input, TextField } from "@mui/material";
import { useAppDispatch } from "../../../store/hooks";
import {
    updateAnswer,
} from "../../../store/slices/documentSlice";
import classes from "./numericQuestionComponent.module.scss";
import { QuestionComment } from "../questionComment/questionCommentComponent";
import { Label } from "../../common/Label/Label"
import { NumericQuestion } from "../../../model/NumericQuestion";
import { AnswerCategory } from "../../../model/AnswerCategory";
import { NumericQuestionOption } from "../../../model/NumericQuestionOption";

import AppContext from "../../global";

export const NumericQuestionComponent: React.FC<{
    question: NumericQuestion;
    answerCategories: AnswerCategory[] | undefined;
}> = (props) => {
    const dispatch = useAppDispatch();
    const [defaultValue] = useState<number>(props.question.answer)
    const [inputFieldValue, setInputFieldValue] = useState<string>();

    function handleInputOnBlur(e: React.FocusEvent<HTMLTextAreaElement>) {

        let number = inputFieldValue === '' ? '' : Number(inputFieldValue);

        dispatch(
            updateAnswer({
                id: props.question.id,
                answer: number as any,
            })
        );
    }

    let range = !!props.answerCategories?.find(item => item.answerCategoryName == props.question.answerCategory)
        ? props.answerCategories.find(item => item.answerCategoryName == props.question.answerCategory).options
        : []

    let min = !!range[0] ? (range[0] as NumericQuestionOption).valueFrom : undefined;
    let max = !!range[0] ? (range[0] as NumericQuestionOption).valueTo : undefined;

    return (
        <Box className={classes.container}>
            <div className={classes.editorDiv}>
                <Label styleName={classes.label}>
                    {props.question.required
                        ? props.question.question + " *"
                        : props.question.question}
                </Label>
                {AppContext.isInPrintPreview ? (
                    <Label styleName={classes.print_label}>{defaultValue}</Label>
                ) : (
                    <Input
                        type="number"
                        className={classes.inputField}
                        onBlur={handleInputOnBlur}
                        onChange={(event) => setInputFieldValue(event.target.value)}
                        defaultValue={defaultValue}
                        inputProps={{ min: min, max: max }}
                    />)}
            </div>
            <div>
                {props.question.showNotes && (
                    <QuestionComment
                        noteText={props.question.note}
                        showNotes={props.question.showNotes}
                        noteRequired={props.question.noteRequired}
                        questionId={props.question.id}
                    />
                )}
            </div>
        </Box>
    );
};
